import React, { useContext } from "react"
import styled from "styled-components"
import LanguageContext from "../contexts/LanguageContext"
import { FaGlobe } from "react-icons/fa"

const LangSelector = () => {
  const { lang } = useContext(LanguageContext)
  const { onLanguageChange } = useContext(LanguageContext)
  return (
    <ListItem>
      <FaGlobe />
      <p
        className={lang === "fr" ? "chosen" : ""}
        onClick={() => onLanguageChange("fr")}
      >
        FR
      </p>
      <p
        className={lang === "nl" ? "chosen" : ""}
        onClick={() => onLanguageChange("nl")}
      >
        NL
      </p>
      <p
        className={lang === "en" ? "chosen" : ""}
        onClick={() => onLanguageChange("en")}
      >
        EN
      </p>
    </ListItem>
  )
}

export default LangSelector

const ListItem = styled.li`
  list-style: none;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  & > svg {
    font-size: 3rem;
    margin-right: 2rem;
    color: goldenrod;
  }
  & > .chosen {
    color: goldenrod;
  }
  & > p {
    margin-right: 1rem;
    font-weight: 200;
    cursor: pointer;
  }
`
