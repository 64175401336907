import React, { useContext } from "react"
import styled from "styled-components"
import { Button } from "../utils"
import LanguageContext from "../contexts/LanguageContext"
import BannerImgWide from "../images/Arnosante.jpg"
import BannerImgCenter from "../images/santeboutiquecenter.jpg"
import BannerImgMobile from "../images/santeboutiquemobile2.jpg"

const MainHeader = () => {
  const { lang } = useContext(LanguageContext)
  return (
    <MainHeaderWrapper>
      <a href="https://backl.ink/arnosanteboutique">
        <Button
          absolute="1"
          text={
            lang === "fr" ? "commander" : lang === "nl" ? "bestellen" : "order"
          }
        />
      </a>
    </MainHeaderWrapper>
  )
}

export default MainHeader

const MainHeaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 4rem;
  background-image: url(${BannerImgWide});
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 1160px) {
    background-image: url(${BannerImgCenter});
  }
  @media screen and (max-width: 560px) {
    background-image: url(${BannerImgMobile});
  }
`
