import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

const Footer = () => {
  return (
    <FooterWrapper>
      made with <span>♥</span> by{" "}
      <a href="https://mastercoda.fr" target="_blank" rel="noopener noreferrer">
        mastercoda
      </a>
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.p`
  text-align: center;
  & > span {
    color: crimson;
  }
  & > a {
    text-decoration: none;
    color: goldenrod;
    ${styles.transObject};
    &:hover {
      color: ${styles.colors.primaryFont};
    }
  }
`
