import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

const Button = ({ text, absolute }) => {
  return (
    <ButtonComponent className={absolute === "1" ? "absolute" : ""}>
      {text}
    </ButtonComponent>
  )
}

export default Button

const ButtonComponent = styled.button`
  display: block;
  padding: 1rem 2rem;
  background-color: ${styles.colors.black};
  color: ${styles.colors.white};
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 300;
  margin: 0 auto;
  cursor: pointer;
  white-space: nowrap;
  ${styles.transObject};
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${styles.colors.primaryFont};
    background-color: goldenrod;
  }
  &.absolute {
    position: absolute;
    top: 86%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 560px) {
      display: none;
    }
  }
`
