import React from "react"
import styled from "styled-components"

const Title = ({ text }) => {
  return <StyledTitle>{text}</StyledTitle>
}

export default Title

const StyledTitle = styled.h2`
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
`
