import React from "react"
import styled from "styled-components"
import { styles } from "../../utils"
import NavToggle from "../nav/NavToggle"
import NavLinks from "../nav/NavLinks"

export default class Nav extends React.Component {
  state = {
    navOpen: false,
  }

  handleNav = () => {
    this.setState(() => {
      return { navOpen: !this.state.navOpen }
    })
  }
  render() {
    return (
      <NavBar
        open={this.state.navOpen}
        // onClick={() => {
        //   /* Logique pour éviter de call handleNavbar en mode desktop */
        //   if (this.state.navOpen) {
        //     return this.handleNav()
        //   }
        // }}
      >
        <NavToggle navOpen={this.state.navOpen} handleNav={this.handleNav} />
        <NavLinks navOpen={this.state.navOpen} handleNav={this.handleNav} />
      </NavBar>
    )
  }
}

const NavBar = styled.div`
  position: fixed;
  right: 3rem;
  top: 3rem;
  height: 22px;
  width: 24px;
  ${props =>
    props.open
      ? `width: 100%; height: 100%; right: 0; top: 0; background-color: ${styles.colors.black}; z-index: 1`
      : ``}
`
