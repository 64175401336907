import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import LanguageContext from "../../contexts/LanguageContext"
import LangSelector from "../LangSelector"
import { styles } from "../../utils"

const NavLinks = props => {
  const { lang } = useContext(LanguageContext)
  const [links] = useState([
    {
      id: 0,
      path: "#album",
      name: "Album",
      nameEN: "Album",
      nameNL: "Album",
    },
    {
      id: 1,
      path: "#shows",
      name: "Concerts",
      nameEN: "Shows",
      nameNL: "Concerten",
    },
    {
      id: 2,
      path: "#videos",
      name: "Clips Videos",
      nameEN: "Video Clips",
      nameNL: "Videoclips",
    },
  ])

  return (
    <LinkList open={props.navOpen}>
      {links.map(item => {
        return (
          <ListItem
            key={item.id}
            onClick={() => {
              /* Logique pour éviter de call handleNavbar en mode desktop */
              if (props.navOpen) {
                return props.handleNav()
              }
            }}
          >
            <LinkItem to={item.path}>
              {lang === "fr"
                ? item.name
                : lang === "nl"
                ? item.nameNL
                : item.nameEN}
            </LinkItem>
          </ListItem>
        )
      })}
      <LangSelector />
    </LinkList>
  )
}

export default NavLinks

const LinkList = styled.ul`
  display: none;
  color: white;
  ${props =>
    props.open
      ? `display: block;
      position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);`
      : ""}
`

const ListItem = styled.li`
  list-style: none;
  margin: 2rem 0;
  & > .chosen {
    color: goldenrod;
  }
  & > p {
    font-weight: 200;
  }
`

const LinkItem = styled(Link)`
  color: goldenrod;
  text-decoration: none;
  font-size: 4rem;
  font-weight: 100;
  ${styles.transObject};
  &:hover {
    color: white;
  }
`
