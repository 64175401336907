import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

const Section = ({ children, color, id }) => {
  return (
    <SectionWrapper id={id}>
      <SectionContainer color={color}>{children}</SectionContainer>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  max-width: 100%;
  padding: 4rem 0;
  background-color: ${props =>
    props.color ? props.color : styles.colors.white};
`
const SectionContainer = styled.div`
  max-width: 1050px;
  padding: 0 3.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
  & > a {
    text-decoration: none;
  }
`

export default Section
