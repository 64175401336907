/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import { Footer } from "../utils"
import Nav from "../components/nav/Nav"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <GlobalStyle />
      <Nav />
      {children}
      <Footer />
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  * { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    color: #262626;
    background: #FFF;
    font-size: 1.6rem;
    
  }

  html {
    font-size: 62.5%;
    @media screen and (max-width: 768px) {
      font-size: 56.25%;
    }
    @media screen and (max-width: 450px) {
      font-size: 50%;
    }
  }

  
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
