export const colors = {
  primary: `#EBEBEB`,
  secondary: `#666`,
  primaryFont: `#000`,
  secondaryFont: `#FFF`,
  white: `#FFF`,
  black: `#1E1E1E`,
}

export const transDefault = "transition: all 0.2s ease-in-out"

export const transObject = ({
  property = "all",
  time = "0.2s",
  type = "linear",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const border = ({
  width = "0.15rem",
  type = "solid",
  color = colors.mainWhite,
}) => {
  return `border: ${width} ${type} ${color}`
}

export const letterSpacing = ({ spacing = "0.1rem" }) => {
  return `letter-spacing: ${spacing}`
}
