import React from "react"
import styled from "styled-components"
import { styles } from "../../utils"

const NavToggle = props => {
  return (
    <BurgerWrapper onClick={() => props.handleNav()}>
      <BurgerLine open={props.navOpen} />
    </BurgerWrapper>
  )
}

export default NavToggle

const BurgerWrapper = styled.div`
  position: fixed;
  right: 3rem;
  top: 3rem;
  height: 22px;
`

const BurgerLine = styled.span`
  display: block;
  width: 24px;
  height: 2px;

  transform: translateY(10px);
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  ${props =>
    props.open
      ? `background-color: transparent;`
      : `background-color: ${styles.colors.black};`}
  /* navOpen background-color: transparent;*/
  &::after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: ${styles.colors.black};
    /* transform: translateY(8px); */
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    ${props =>
      props.open
        ? `transform: translateY(-2px) rotate(-45deg); background-color: white;`
        : `transform: translateY(8px);`} /* navOpen transform: translateY(-2px) rotate(-45deg); */
  }
  &::before {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: ${styles.colors.black};
    transform: translateY(-10px);
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    ${props =>
      props.open
        ? `transform: translateY(0px) rotate(45deg); background-color: white;`
        : `transform: translateY(-10px);`} /* navOpen transform: translateY(0px) rotate(45deg); */
  }
`
